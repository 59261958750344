<template>
    <div class="d-flex align-center justify-center">
        <img height="200px" src="@/assets/transplant-active-logo.svg">
    </div>
</template>

<script>
import { goTo } from "@/services/routeService";
import tableRoutes from '@/routes/tableRoutes';
import { camelToTitleCase } from "@/services/stringUtility";
import { canList } from "@/features/schemas/services/schemaApi";

export default {
    computed: {
        routes() {
            return tableRoutes.filter(r => canList(r.staticProps.entityKey));
        }
    },

    methods: {
        goTo,
        getTitle(route) {
            return camelToTitleCase(route.name);
        }
    },
};
</script>
